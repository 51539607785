@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Raleway:400,100,200,300,500,600,700,800,900);

html,
body {
  height: 100%;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  line-height: 24px;
  vertical-align: baseline;
  color: #6a6a6a;
}
.floatleft {
  float: left;
}
.floatright {
  float: right;
}
.alignleft {
  float: left;
  margin-right: 15px;
  margin-bottom: 15px;
}
.alignright {
  float: right;
  margin-left: 15px;
  margin-bottom: 15px;
}
.aligncenter {
  display: block;
  margin: 0 auto 15px;
}
a:focus {
  outline: 0px solid;
}

a {
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  text-decoration: none;
}
a:hover {
  color: #0a6f85;
  text-decoration: none;
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}
a:active,
a:hover {
  outline: 0 none;
}
ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}
.clear {
  clear: both;
}

.mobile-menu-area {
  display: none;
}
.padding-top {
  padding-top: 30px;
}
.padding-top1 {
  padding-top: 60px;
}

/* ------------------------------------
2.header area start here 
---------------------------------------*/
.header-top-area {
  background: #222222;
  padding: 10px 0;
}
.header-top-area .header-top-left ul {
  text-align: left;
}
.header-top-area .header-top-left ul li {
  display: inline-block;
  color: #cacaca;
}
.header-top-area .header-top-left ul li i {
  margin: 0 5px;
  background: #01b1d7;
  color: #ffffff;
  padding: 5px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.header-top-area .header-top-right ul {
  text-align: right;
}
.header-top-area .header-top-right ul li {
  display: inline-block;
  color: #cacaca;
}
.header-top-area .header-top-right ul li i {
  margin: 0 5px;
  background: #01b1d7;
  color: #ffffff;
  padding: 5px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.main-header-area {
  background: #14233e99;
  position: fixed;
  z-index: 99;
  width: 100%;
}

.main-header-area-relative {
  background: #14243e;
  position: relative;
}
.main-header-area .logo-area {
  padding: 28px 0;
}
.main-header-area .logo-area a {
  display: block;
  text-align: center;
}
.main-header-area .main-menu ul {
  text-align: center;
  float: right;
}
.main-header-area .main-menu ul li {
  display: inline-block;
  padding: 36px 25px;
  position: relative;
  transition: all 0.5s ease 0s;
}
.main-header-area .main-menu ul li.active a {
  color: #01b1d7;
}
.main-header-area .main-menu ul li:hover a {
  color: #01b1d7;
}
.main-header-area .main-menu ul li:hover ul {
  -webkit-transform: scaleY(1);
  -moz-transform: scaleY(1);
  -o-transform: scaleY(1);
  transform: scaleY(1);
  opacity: 1;
  visibility: visible;
  top: 100%;
}
.main-header-area .main-menu ul li ul {
  background: #ffffff;
  position: absolute;
  top: 110%;
  transition: all 0.5s ease 0s;
  transform-origin: 0 0 0;
  -webkit-transform: scaleY(0);
  -moz-transform: scaleY(0);
  -o-transform: scaleY(0);
  transform: scaleY(0);
  opacity: 0;
  visibility: hidden;
  width: 200px;
  z-index: 9;
  left: 0;
}
.main-header-area .main-menu ul li ul li {
  display: block;
  padding: 7px;
  text-align: center;
  border-bottom: 1px solid #ffffff;
  transition: all 0.5s ease 0s;
}
.main-header-area .main-menu ul li ul li:hover {
  /* background: #01b1d7; */
}
.main-header-area .main-menu ul li ul li:hover a {
  color: #01b1d7 !important;
}
.main-header-area .main-menu ul li ul li:last-child {
  border-bottom: 0px;
}
.main-header-area .main-menu ul li ul li a {
  color: #222222 !important;
}
.main-header-area .main-menu ul li a {
  display: block;
  text-decoration: none;
  color: #fff;
  transition: all 0.5s ease 0s;
}

.main-header-area .search-area {
  padding: 30px 0;
}
.main-header-area .search-area #custom-search-input {
  margin: 0;
  margin-top: 0px;
  padding: 0;
}
.main-header-area .search-area #custom-search-input .search-query {
  padding-right: 3px;
  padding-right: 4px;
  padding-left: 3px;
  padding-left: 4px;
  /* IE7-8 doesn't have border-radius, so don't indent the padding */
  margin-bottom: 0;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.main-header-area .search-area #custom-search-input button {
  border: 0;
  background: none;
  /** belows styles are working good */
  padding: 2px 5px;
  margin-top: 2px;
  position: relative;
  left: -28px;
  /* IE7-8 doesn't have border-radius, so don't indent the padding */
  margin-bottom: 0;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  color: #01b1d7;
}
.main-header-area .search-area .search-query:focus + button {
  z-index: 9;
}

.mob_container {
  padding: 0px;
  background: #14233e !important;
}
.mob_container nav {
  padding: 10px;
}

#sidebar ul li {
  display: block;
}
#SEOsubmenu, #Servicesubmenu {
  padding-left: 25px;
}
#sidebarCollapse {
  color: #ffffff;
  font-size: 26px;
}