.legend  {
    color: white !important;
    background-color: #14233e99 !important;
    display: flex;
    justify-content: center;
    align-content: center !important;
    align-items: center !important;
    align-self: center !important;
    /* width: 50% !important; */
    bottom: 10% !important;
    font-size: 30px !important;
    font-weight: bold !important;
    /* left: 70% !important; */
}

.home-container {
    align-items: center;
    align-content: center;
    align-self: center
}

.thumbs {
    display: flex;
    align-items: center;
    align-content: center;
    align-self: center
}

.carousal-slider {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.carousel {
    height: 400px;
    width: 100%;
    height: 100%;
    background-color: #14233e99;
    display: flex;
    justify-content: center
  }