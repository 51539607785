@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Raleway:400,100,200,300,500,600,700,800,900);
@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700);
@import url(https://fonts.googleapis.com/css?family=Numans);
.image-background{
  background-image: url(/static/media/adult-business-businessman-1061588.70313051.jpg);
  background-repeat: no-repeat;
  /* background-position: center; */
  background-size: cover;
  background-attachment: fixed;
}

body {
  font-family: 'Open Sans', sans-serif;
}
.App {
  text-align: center;
}
.navbar-dark {
  background: #61dafb;
  height: 80px;
}
.App-logoTop {
  width: 145px;
}
.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 120px;
}
.navbar-dark .navbar-nav .nav-link {
  color: #fff !important;
}
.App-header {
  background-color: #222;
  height: 500px;
  padding: 20px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.App-title {
  font-size: 1.5em;
  font-family: 'Open Sans', sans-serif;
  padding-top: 15px;
}
.Welcome-title {
  font-family: 'Open Sans', sans-serif;
}
.App-intro {
  font-size: large;
}
.navbar-nav li {
  background: #000;
  margin-left: 10px;
}

@-webkit-keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
.speakerHeading {
  width: 100%;
  margin: auto;
}
.section1 {
  margin-bottom: 20px;
  box-shadow: 10px 10px 19px -12px rgba(0, 0, 0, 0.5);
  background: #fafafa;
  min-height: 300px;
}
.speakerWrapper {
  justify-content: center;
}
.footer {
  height: 60px;
  background: #222;
  color: #fff;  
}
.innerFooter {
  align-items: center;
  height: 100%;
}
.innerContainer {
    height: 100%;
}
.rajat-img {
  width: 100%;
  height: 300px;
}
.rajat-nameHeading {
  font-size: 24px;
  padding: 15px 0px 0px 0px;
  margin-bottom: 5px;
}
.search-barWrapper {
  background: #222;
  height: 60px;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
}
.search-barWrapper button {
  margin-right: 10px;
}
.images_wrapper img {
  width: 100%;
  height: 300px;
  margin-top: 15px;
}
.images_wrapper{
  margin: 0px !important;
  margin-right: 15px !important;
}
.image_wrapper_inner {
  padding-right: 0px !important;
  box-shadow: 10px 10px 19px -12px rgba(0, 0, 0, 0.5);
  background: #fafafa;
}

/* NAVIGATION */
nav ul {
  list-style: none;
  text-align: left;
  margin-bottom: 0px;
}
nav ul li {
  display: inline-block;
  font-family: 'Open Sans', sans-serif;
}
nav ul li a {
  display: block;
  padding: 15px;
  text-decoration: none;
  color: #fff;
  font-weight: 800;
  margin: 0 10px;
}
nav ul li a:hover {
  text-decoration: none;
}
nav ul li a,
nav ul li a:after,
nav ul li a:before {
  transition: all .5s;
}
nav ul li a:hover {
  color: #555;
}


/* stroke */
nav.stroke ul li a,
nav.fill ul li a {
  position: relative;
}
nav.stroke ul li a:after,
nav.fill ul li a:after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 0%;
  content: '.';
  color: transparent;
  background: #aaa;
  height: 1px;
}
nav.stroke ul li a:hover:after {
  width: 100%;
}

nav.fill ul li a {
  transition: all 2s;
}

nav.fill ul li a:after {
  text-align: left;
  content: '.';
  margin: 0;
  opacity: 0;
}
nav.fill ul li a:hover {
  color: #fff;
  z-index: 1;
}
nav.fill ul li a:hover:after {
  z-index: -10;
  animation: fill 1s forwards;
  -webkit-animation: fill 1s forwards;
  -moz-animation: fill 1s forwards;
  opacity: 1;
}



/* Keyframes */
@-webkit-keyframes fill {
  0% {
    width: 0%;
    height: 1px;
  }
  50% {
    width: 100%;
    height: 1px;
  }
  100% {
    width: 100%;
    height: 100%;
    background: #50bad7;
  }
}





html,
body {
  height: 100%;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  line-height: 24px;
  vertical-align: baseline;
  color: #6a6a6a;
}
.floatleft {
  float: left;
}
.floatright {
  float: right;
}
.alignleft {
  float: left;
  margin-right: 15px;
  margin-bottom: 15px;
}
.alignright {
  float: right;
  margin-left: 15px;
  margin-bottom: 15px;
}
.aligncenter {
  display: block;
  margin: 0 auto 15px;
}
a:focus {
  outline: 0px solid;
}

a {
  transition: all 0.5s ease 0s;
  text-decoration: none;
}
a:hover {
  color: #0a6f85;
  text-decoration: none;
  transition: all 0.5s ease 0s;
}
a:active,
a:hover {
  outline: 0 none;
}
ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}
.clear {
  clear: both;
}

.mobile-menu-area {
  display: none;
}
.padding-top {
  padding-top: 30px;
}
.padding-top1 {
  padding-top: 60px;
}

/* ------------------------------------
2.header area start here 
---------------------------------------*/
.header-top-area {
  background: #222222;
  padding: 10px 0;
}
.header-top-area .header-top-left ul {
  text-align: left;
}
.header-top-area .header-top-left ul li {
  display: inline-block;
  color: #cacaca;
}
.header-top-area .header-top-left ul li i {
  margin: 0 5px;
  background: #01b1d7;
  color: #ffffff;
  padding: 5px;
  border-radius: 50%;
}
.header-top-area .header-top-right ul {
  text-align: right;
}
.header-top-area .header-top-right ul li {
  display: inline-block;
  color: #cacaca;
}
.header-top-area .header-top-right ul li i {
  margin: 0 5px;
  background: #01b1d7;
  color: #ffffff;
  padding: 5px;
  border-radius: 50%;
}
.main-header-area {
  background: #14233e99;
  position: fixed;
  z-index: 99;
  width: 100%;
}

.main-header-area-relative {
  background: #14243e;
  position: relative;
}
.main-header-area .logo-area {
  padding: 28px 0;
}
.main-header-area .logo-area a {
  display: block;
  text-align: center;
}
.main-header-area .main-menu ul {
  text-align: center;
  float: right;
}
.main-header-area .main-menu ul li {
  display: inline-block;
  padding: 36px 25px;
  position: relative;
  transition: all 0.5s ease 0s;
}
.main-header-area .main-menu ul li.active a {
  color: #01b1d7;
}
.main-header-area .main-menu ul li:hover a {
  color: #01b1d7;
}
.main-header-area .main-menu ul li:hover ul {
  transform: scaleY(1);
  opacity: 1;
  visibility: visible;
  top: 100%;
}
.main-header-area .main-menu ul li ul {
  background: #ffffff;
  position: absolute;
  top: 110%;
  transition: all 0.5s ease 0s;
  transform-origin: 0 0 0;
  transform: scaleY(0);
  opacity: 0;
  visibility: hidden;
  width: 200px;
  z-index: 9;
  left: 0;
}
.main-header-area .main-menu ul li ul li {
  display: block;
  padding: 7px;
  text-align: center;
  border-bottom: 1px solid #ffffff;
  transition: all 0.5s ease 0s;
}
.main-header-area .main-menu ul li ul li:hover {
  /* background: #01b1d7; */
}
.main-header-area .main-menu ul li ul li:hover a {
  color: #01b1d7 !important;
}
.main-header-area .main-menu ul li ul li:last-child {
  border-bottom: 0px;
}
.main-header-area .main-menu ul li ul li a {
  color: #222222 !important;
}
.main-header-area .main-menu ul li a {
  display: block;
  text-decoration: none;
  color: #fff;
  transition: all 0.5s ease 0s;
}

.main-header-area .search-area {
  padding: 30px 0;
}
.main-header-area .search-area #custom-search-input {
  margin: 0;
  margin-top: 0px;
  padding: 0;
}
.main-header-area .search-area #custom-search-input .search-query {
  padding-right: 3px;
  padding-right: 4px;
  padding-left: 3px;
  padding-left: 4px;
  /* IE7-8 doesn't have border-radius, so don't indent the padding */
  margin-bottom: 0;
  border-radius: 3px;
}
.main-header-area .search-area #custom-search-input button {
  border: 0;
  background: none;
  /** belows styles are working good */
  padding: 2px 5px;
  margin-top: 2px;
  position: relative;
  left: -28px;
  /* IE7-8 doesn't have border-radius, so don't indent the padding */
  margin-bottom: 0;
  border-radius: 3px;
  color: #01b1d7;
}
.main-header-area .search-area .search-query:focus + button {
  z-index: 9;
}

.mob_container {
  padding: 0px;
  background: #14233e !important;
}
.mob_container nav {
  padding: 10px;
}

#sidebar ul li {
  display: block;
}
#SEOsubmenu, #Servicesubmenu {
  padding-left: 25px;
}
#sidebarCollapse {
  color: #ffffff;
  font-size: 26px;
}
/* Large desktop :992px. */
@media (min-width: 1170px) and (max-width: 1400px) {
    .header-top-area .header-top-left ul li {font-size: 12px;}
    .main-header-area .main-menu ul li, .home2 .main-header-area .main-menu ul li {padding: 36px 15px;}
    .home-about-area .about-content-area h2 {font-size: 33px;}
    .inner-page .about-page-content .feature-image .about-main-content {margin: 7px 0 0 -35px;}
    .home-team-area .total-team .single-team .overley:hover .social-media-icons {top: 60%;  transform: translateY(-60%);}
    .home-team-area .total-team .single-team .team-details h3 a {font-size: 15px;}
    .call-top-action .subscribe-now a {font-size: 16px;padding: 10px 8px;}
    .news-page-area .news-main-content .single-news-area .news-featured-image .date-area, .single-news-page-area .single-news-page .news-featured-image .date-area {margin: 10px;}
    .single-news-page-area .author-post .single-author-post {padding: 5px;}
    footer .footer-top-area .main-footer .single-footer .footer-social-media-area ul li {margin: 1px;}
    footer .footer-top-area .main-footer .footer-four ul li {margin: 1px 2px;}
    .home-news-area{padding: 60px 0 90px;}
    footer .footer-top-area {  padding: 150px 0 60px;}
    .home-about-area, .service-area, .home-faq-area, .home-counter-down-area, .home-team-area, .home-callback-area, .inner-page-header, .inner-page .about-page-content, .inner-page .footer-top-area, .total-blog-area, .call-top-action, .our-project-one, .our-project-two, .news-page-area, .contact-us-page-area, .error-page-area {  padding: 60px 0;}
    
    
    }
    /* Normal desktop :992px. */
    @media (min-width: 992px) and (max-width: 1169px) {
    .header-top-area .header-top-left ul li {font-size: 12px;}
    .main-header-area .main-menu ul li, .home2 .main-header-area .main-menu ul li {padding: 36px 15px;}
    .home-about-area .about-content-area h2 {font-size: 33px;}
    .inner-page .about-page-content .feature-image .about-main-content {margin: 7px 0 0 -35px;}
    .home-team-area .total-team .single-team .overley:hover .social-media-icons {top: 60%;  transform: translateY(-60%);}
    .home-team-area .total-team .single-team .team-details h3 a {font-size: 15px;}
    .call-top-action .subscribe-now a {font-size: 16px;padding: 10px 8px;}
    .news-page-area .news-main-content .single-news-area .news-featured-image .date-area, .single-news-page-area .single-news-page .news-featured-image .date-area {margin: 10px;}
    .single-news-page-area .author-post .single-author-post {padding: 5px;}
    footer .footer-top-area .main-footer .single-footer .footer-social-media-area ul li {margin: 1px;}
    footer .footer-top-area .main-footer .footer-four ul li {margin: 1px 2px;}
    .home-testimonial-area .total-testimonial {background-position:center center;}
    .slider-area .slider-1 h1, .slider-area .slider-2 h1 {font-size: 60px;}
    .slider-area .slider-1 div.title2, .slider-area .slider-2 div.title2 {padding: 0 10%;}
    .total-blog-area .blog-sidebar-area .single-sidebar .sidebar-testimonial .single-testimonial {padding: 60px 20px;}
    .contact-us-page-area .contact-box .single-contact-box p {font-size: 16px;}
    .home-page-core-activities-area .home-activities-area h2 {left: -34%;}
    .home-news-area{padding: 50px 0 70px;}
    footer .footer-top-area {  padding: 150px 0 50px;}
    .home-about-area, .service-area, .home-faq-area, .home-counter-down-area, .home-team-area, .home-callback-area, .inner-page-header, .inner-page .about-page-content, .inner-page .footer-top-area, .total-blog-area, .call-top-action, .our-project-one, .our-project-two, .news-page-area, .contact-us-page-area, .error-page-area {  padding: 50px 0;}
    
    }
    
     
    /* Tablet desktop :768px. */
    @media (min-width: 768px) and (max-width: 991px) {
    .header-top-area .header-top-left ul li {font-size: 14px;}
    .main-header-area .main-menu ul li, .home2 .main-header-area .main-menu ul li {padding:15px;}
    .home-about-area .about-content-area h2 {font-size: 33px;}
    .inner-page .about-page-content .feature-image .about-main-content {margin: 7px 0 0 -35px;}
    .home-team-area .total-team .single-team .overley:hover .social-media-icons {top: 60%;  transform: translateY(-60%);}
    .home-team-area .total-team .single-team .team-details h3 a {font-size: 15px;}
    .call-top-action .subscribe-now a {font-size: 16px;padding: 10px 8px;}
    .news-page-area .news-main-content .single-news-area .news-featured-image .date-area, .single-news-page-area .single-news-page .news-featured-image .date-area {margin: 10px;}
    .single-news-page-area .author-post .single-author-post {padding: 5px;}
    footer .footer-top-area .main-footer .single-footer .footer-social-media-area ul li {margin: 1px;}
    footer .footer-top-area .main-footer .footer-four ul li {margin: 1px 2px;}
    .home-testimonial-area .total-testimonial {background-position:center center;}
    .slider-area .slider-1 h1, .slider-area .slider-2 h1 {font-size: 40px;}
    .slider-area .slider-1 div.title2, .slider-area .slider-2 div.title2 {padding: 0 10%;}
    .total-blog-area .blog-sidebar-area .single-sidebar .sidebar-testimonial .single-testimonial {padding: 60px 20px;}
    .contact-us-page-area .contact-box .single-contact-box p {font-size: 14px;}
    .header-top-area .header-top-left ul,.header-top-area .header-top-right ul, .single-about-services {  text-align: center;margin-bottom: 15px}
    .main-header-area .search-area{display: none;}
    .service-area .single-service .media .media-body h4.media-heading a {font-size: 16px;}
    .home-page-core-activities-area .home-activities-area h2 {left: -44%;}
    .service-area .single-service .media .pull-left { margin-right: 0;}
    .home-page-core-activities-area .home-activities-area h2 {top: 40%;}
    .home-faq-area .faq-area h2 {font-size: 23px;margin-bottom: 37px;}
    .home-counter-down-area .ab-count .about-counter-list {border-right: 0px solid #ffffff;}
    .home-counter-down-title {  margin-bottom: 35px;  text-align: center;}
    .home-counter-down-area .ab-count .about-counter-list h1.about-counter {margin-bottom: 0;}
    .home-counter-down-area .ab-count .about-counter-list p {margin-bottom: 20px;}
    footer .footer-top-area .main-footer .single-footer h3 {font-size: 20px;}
    .home2 .main-header-area {position: inherit;background: #222222}
    .inner-page .about-page-content .about-title-area h2 {height: 25px;}
    .inner-page .about-page-content .feature-image .about-featured-image { margin-bottom: 30px;}
    .inner-page .about-page-content .feature-image .about-main-content {  margin: 0;  text-align: center;}
    .inner-page .about-page-content .about-service .single-about-services .service-top ul{  margin-bottom: 30px;  text-align: center;}
    .call-top-action, .contact-us-page-area .contact-box .single-contact-box{text-align: center;}
    .call-top-action .subscribe-text h2 {margin: 0 0 20px;}
    .single-news-page-area .content-info .blog-content-share-social-icons ul li {  margin: 2px;}
    .contact-us-page-area .contact-box .single-contact-box p span {  margin-left: 0;}
    .contact-us-page-area .contact-box .single-contact-box p i {display: block;margin-bottom: 20px;margin-right: 0;}
    .home-news-area{padding: 50px 0 70px;}
    footer .footer-top-area {  padding: 150px 0 50px;}
    .home-about-area, .service-area, .home-faq-area, .home-counter-down-area, .home-team-area, .home-callback-area, .inner-page-header, .inner-page .about-page-content, .inner-page .footer-top-area, .total-blog-area, .call-top-action, .our-project-one, .our-project-two, .news-page-area, .contact-us-page-area, .error-page-area {  padding: 50px 0;}
    
    
    }
    
     
    /* small mobile :320px. */
    @media only screen and (min-width: 480px) and (max-width: 767px) {
    .container {width:300px}
    .mobile-menu-area{display: block;}
    .main-header-area, .home2 .main-header-area, .search-area, .header-top-area{display: none;}
    .header-top-area .header-top-left ul li {font-size: 14px;}
    .main-header-area .main-menu ul li, .home2 .main-header-area .main-menu ul li {padding:15px;}
    .home-about-area .about-content-area h2 {font-size: 20px;margin-bottom: 20px;}
    .home-counter-down-area .home-counter-down-title h2 {font-size: 30px;}
    .home-counter-down-area .home-counter-down-title h2 span {font-size: 33px;}
    .inner-page .about-page-content .feature-image .about-main-content {margin: 7px 0 0 -35px;}
    .home-team-area .total-team .single-team .overley:hover .social-media-icons {top: 60%;  transform: translateY(-60%);}
    .home-team-area .total-team .single-team .team-details h3 a {font-size: 15px;}
    .call-top-action .subscribe-now a {font-size: 16px;padding: 10px 8px;}
    .news-page-area .news-main-content .single-news-area .news-featured-image .date-area, .single-news-page-area .single-news-page .news-featured-image .date-area {margin: 10px;}
    .single-news-page-area .author-post .single-author-post {padding: 30px;text-align: center;}
    footer .footer-top-area .main-footer .single-footer .footer-social-media-area ul li {margin: 1px;}
    footer .footer-top-area .main-footer .footer-four ul li {margin: 1px 2px;}
    .home-testimonial-area .total-testimonial {background-position:center center;}
    .slider-area .slider-1 h1, .slider-area .slider-2 h1 {font-size: 22px;}
    .slider-area .slider-1 div.title2, .slider-area .slider-2 div.title2 {padding: 0 10%;display: none;}
    .total-blog-area .blog-sidebar-area .single-sidebar .sidebar-testimonial .single-testimonial {padding: 60px 20px;}
    .contact-us-page-area .contact-box .single-contact-box p {font-size: 14px;}
    .header-top-area .header-top-left ul,.header-top-area .header-top-right ul, .single-about-services {  text-align: center;margin-bottom: 15px}
    .main-header-area .search-area, .home-testimonial-area .total-testimonial .home-testimonial .single-testimonial p i{display: none;}
    .service-area .single-service .media .media-body h4.media-heading a {font-size: 16px;}
    .home-page-core-activities-area .home-activities-area h2 {left: -80%;}
    .service-area .single-service .media .pull-left, .home-page-core-activities-area .home-activities-area .single-activities .media .pull-left, .single-news-page-area .author-post .single-author-post .media .pull-left, .single-news-page-area .author-comment .single-author-comment .media .pull-left { display: inline-block;  float: none !important; margin-right: 0;  text-align: center;}
    .home-page-core-activities-area .home-activities-area h2 {top: 29%;}
    .home-faq-area .faq-area h2 {font-size: 18px;margin-bottom: 37px;text-align: center;}
    .home-counter-down-area .ab-count .about-counter-list {border-right: 0px solid #ffffff;}
    .home-counter-down-title {  margin-bottom: 35px;  text-align: center;}
    .home-counter-down-area .ab-count .about-counter-list h1.about-counter {margin-bottom: 0;}
    .home-counter-down-area .ab-count .about-counter-list p {margin-bottom: 20px;}
    footer .footer-top-area .main-footer .single-footer h3 {font-size: 20px;}
    .home2 .main-header-area {position: inherit;background: #222222}
    .inner-page .about-page-content .about-title-area h2 {height:0px;font-size: 24px;}
    .inner-page .about-page-content .feature-image .about-featured-image { margin-bottom: 30px;}
    .inner-page .about-page-content .feature-image .about-main-content {  margin: 0;  text-align: center;padding: 30px;}
    .inner-page .about-page-content .about-service .single-about-services .service-top ul{  margin-bottom: 30px;  text-align: center;}
    .call-top-action, .contact-us-page-area .contact-box .single-contact-box{text-align: center;}
    .call-top-action .subscribe-text h2 {margin: 0 0 20px;}
    .single-news-page-area .content-info .blog-content-share-social-icons ul li {  margin: 0px;}
    .contact-us-page-area .contact-box .single-contact-box p span {  margin-left: 0;}
    .contact-us-page-area .contact-box .single-contact-box p i {display: block;margin-bottom: 20px;margin-right: 0;}
    .header-top-area .header-top-right ul li i {height: 30px;line-height: 24px;width: 30px;}
    .header-top-area .header-top-right ul li {display: block;margin-bottom: 16px;}
    .slider-area .slider-1 div.slider-botton ul li a, .slider-area .slider-2 div.slider-botton ul li a, .slider-area .slider-1 div.slider-botton ul li.acitve a, .slider-area .slider-2 div.slider-botton ul li.acitve a {padding: 10px 20px;}
    .single-service , .about-content-area {  margin-bottom: 30px;  text-align: center;}
    .home-page-core-activities-area {background: #01b1d7 ;}
    .home-page-core-activities-area .home-activities-area .single-activities {margin-bottom: 30px;margin-left: 0;  text-align: center;}
    .home-faq-area .faq-area h2::after, .home-callback-area .call-back-content h2::after, .total-blog-area .single-blog-post h2::after, .contact-us-page-area .contact-us-page h2::after, .contact-us-page-area .leave-comments-area h4::after {margin: auto; right: 0;}
    .site-section-area{padding:0}
    .home-callback-area .call-back-content h2{text-align: center;}
    .home-callback-area .call-back-content p, .single-news{margin: 0;text-align: center;margin-bottom: 30px;}
    .home-testimonial-area .total-testimonial .home-testimonial .single-testimonial p {margin: 0; padding: 0;}
    footer .footer-top-area .footer-top p { letter-spacing: 4px;}
    footer .footer-top-area .footer-top h2 {font-size: 32px;}
    .inner-page .about-page-content .about-title-area p {  margin-top: 67px;  padding: 0;}
    .inner-page .about-page-content .feature-image .about-main-content h3 {font-size: 18px;}
    .inner-page .about-page-content .about-service .single-about-services .service-top ul li {  display: block;}
    .inner-page-header .header-page-locator ul, .header-page-title, .total-blog-area .single-blog-post, .single-news-page-area .author-comment .single-author-comment{  text-align: center;}
    .total-blog-area .single-blog-post h2 {font-size: 21px;}
    .blog-sidebar-area, .sidebar-area {  margin-top: 30px;}
    .news-page-area .news-main-content .pagination-area ul li a {height: 30px;line-height: 30px;width: 30px;}
    .single-news-page-area .single-news-page h3 a {font-size: 17px;}
    .single-news-page-area .author-post .single-author-post .media .pull-left, .single-news-page-area .author-comment .single-author-comment .media .pull-left {margin-bottom: 30px;width: 100%;}
    .single-news-page-area .author-comment .single-author-comment .media .media-body ul li.right{display: inline-block;float: none !important;text-align: center;width: 100%;}
    .contact-us-page-area .contact-us-page h2{  text-align: center;font-size: 20px;}
    .contact-us-page-area .contact-box .single-contact-box {  border-right: 0px solid #e1e1e1;}
    .contact-us-page-area .leave-comments-area fieldset .btn-send { width: 100%;}
    .error-page-area .error-page h1{font-size: 100px;}
    .error-page-area .error-page{padding: 30px;}
    .nivo-directionNav a {top: 34%;}
    .home-callback-area .call-back-content h2 {font-size: 24px;}
    .our-project-one .pagination-area ul li a {height: 35px;line-height: 35px; width: 35px;}
    .home-counter-down-area .home-counter-down-title p {letter-spacing: 7px}
    .error-page-area .error-page p { font-size: 22px;}
    .home-news-area{padding: 50px 0 70px;}
    footer .footer-top-area {  padding: 150px 0 50px;}
    .home-about-area, .service-area, .home-faq-area, .home-counter-down-area, .home-team-area, .home-callback-area, .inner-page-header, .inner-page .about-page-content, .inner-page .footer-top-area, .total-blog-area, .call-top-action, .our-project-one, .our-project-two, .news-page-area, .contact-us-page-area, .error-page-area {  padding: 50px 0;}
    }
     
    /* Large Mobile :480px. */
    @media only screen and (min-width: 200px) and (max-width: 479px) {
        .container {width:300px}
        .mobile-menu-area{display: block;}
        .main-header-area, .home2 .main-header-area, .search-area, .slider-content, .header-top-area{display: none;}
        .header-top-area .header-top-left ul li {font-size: 14px;}
        .main-header-area .main-menu ul li, .home2 .main-header-area .main-menu ul li {padding:15px;}
        .home-about-area .about-content-area h2 {font-size: 20px;margin-bottom: 20px;}
        .home-counter-down-area .home-counter-down-title h2 {font-size: 30px;}
        .home-counter-down-area .home-counter-down-title h2 span {font-size: 33px;}
        .inner-page .about-page-content .feature-image .about-main-content {margin: 7px 0 0 -35px;}
        .home-team-area .total-team .single-team .overley:hover .social-media-icons {top: 60%;  transform: translateY(-60%);}
        .home-team-area .total-team .single-team .team-details h3 a {font-size: 15px;}
        .call-top-action .subscribe-now a {font-size: 16px;padding: 10px 8px;}
        .news-page-area .news-main-content .single-news-area .news-featured-image .date-area, .single-news-page-area .single-news-page .news-featured-image .date-area {margin: 10px;}
        .single-news-page-area .author-post .single-author-post {padding: 30px;text-align: center;}
        footer .footer-top-area .main-footer .single-footer .footer-social-media-area ul li {margin: 1px;}
        footer .footer-top-area .main-footer .footer-four ul li {margin: 1px 2px;}
        .home-testimonial-area .total-testimonial {background-position:center center;}
        .slider-area .slider-1 h1, .slider-area .slider-2 h1 {font-size: 22px;}
        .slider-area .slider-1 div.title2, .slider-area .slider-2 div.title2 {padding: 0 10%;display: none;}
        .total-blog-area .blog-sidebar-area .single-sidebar .sidebar-testimonial .single-testimonial {padding: 60px 20px;}
        .contact-us-page-area .contact-box .single-contact-box p {font-size: 14px;}
        .header-top-area .header-top-left ul,.header-top-area .header-top-right ul, .single-about-services {  text-align: center;margin-bottom: 15px}
        .main-header-area .search-area, .home-testimonial-area .total-testimonial .home-testimonial .single-testimonial p i{display: none;}
        .service-area .single-service .media .media-body h4.media-heading a {font-size: 16px;}
        .home-page-core-activities-area .home-activities-area h2 {left: -80%;}
        .service-area .single-service .media .pull-left, .home-page-core-activities-area .home-activities-area .single-activities .media .pull-left, .single-news-page-area .author-post .single-author-post .media .pull-left, .single-news-page-area .author-comment .single-author-comment .media .pull-left { display: inline-block;  float: none !important; margin-right: 0;  text-align: center;}
        .home-page-core-activities-area .home-activities-area h2 {top: 29%;}
        .home-faq-area .faq-area h2 {font-size: 18px;margin-bottom: 37px;text-align: center;}
        .home-counter-down-area .ab-count .about-counter-list {border-right: 0px solid #ffffff;}
        .home-counter-down-title {  margin-bottom: 35px;  text-align: center;}
        .home-counter-down-area .ab-count .about-counter-list h1.about-counter {margin-bottom: 0;}
        .home-counter-down-area .ab-count .about-counter-list p {margin-bottom: 20px;}
        footer .footer-top-area .main-footer .single-footer h3 {font-size: 20px;}
        .home2 .main-header-area {position: inherit;background: #222222}
        .inner-page .about-page-content .about-title-area h2 {height:0px;font-size: 24px;}
        .inner-page .about-page-content .feature-image .about-featured-image { margin-bottom: 30px;}
        .inner-page .about-page-content .feature-image .about-main-content {  margin: 0;  text-align: center;padding: 30px;}
        .inner-page .about-page-content .about-service .single-about-services .service-top ul{  margin-bottom: 30px;  text-align: center;}
        .call-top-action, .contact-us-page-area .contact-box .single-contact-box{text-align: center;}
        .call-top-action .subscribe-text h2 {margin: 0 0 20px;}
        .single-news-page-area .content-info .blog-content-share-social-icons ul li {  margin: 0px;}
        .contact-us-page-area .contact-box .single-contact-box p span {  margin-left: 0;}
        .contact-us-page-area .contact-box .single-contact-box p i {display: block;margin-bottom: 20px;margin-right: 0;}
        .header-top-area .header-top-right ul li i {height: 30px;line-height: 24px;width: 30px;}
        .header-top-area .header-top-right ul li {display: block;margin-bottom: 16px;}
        .slider-area .slider-1 div.slider-botton ul li a, .slider-area .slider-2 div.slider-botton ul li a, .slider-area .slider-1 div.slider-botton ul li.acitve a, .slider-area .slider-2 div.slider-botton ul li.acitve a {padding: 10px 20px;}
        .single-service , .about-content-area {  margin-bottom: 30px;  text-align: center;}
        .home-page-core-activities-area {background: #01b1d7 ;}
        .home-page-core-activities-area .home-activities-area .single-activities {margin-bottom: 30px;margin-left: 0;  text-align: center;}
        .home-faq-area .faq-area h2::after, .home-callback-area .call-back-content h2::after, .total-blog-area .single-blog-post h2::after, .contact-us-page-area .contact-us-page h2::after, .contact-us-page-area .leave-comments-area h4::after {margin: auto; right: 0;}
        .site-section-area{padding:0}
        .home-callback-area .call-back-content h2{text-align: center;}
        .home-callback-area .call-back-content p, .single-news{margin: 0;text-align: center;margin-bottom: 30px;}
        .home-testimonial-area .total-testimonial .home-testimonial .single-testimonial p {margin: 0; padding: 0;}
        footer .footer-top-area .footer-top p { letter-spacing: 4px;}
        footer .footer-top-area .footer-top h2 {font-size: 32px;}
        .inner-page .about-page-content .about-title-area p {  margin-top: 67px;  padding: 0;}
        .inner-page .about-page-content .feature-image .about-main-content h3 {font-size: 18px;}
        .inner-page .about-page-content .about-service .single-about-services .service-top ul li {  display: block;}
        .inner-page-header .header-page-locator ul, .header-page-title, .total-blog-area .single-blog-post, .single-news-page-area .author-comment .single-author-comment{  text-align: center;}
        .total-blog-area .single-blog-post h2 {font-size: 21px;}
        .blog-sidebar-area, .sidebar-area {  margin-top: 30px;}
        .news-page-area .news-main-content .pagination-area ul li a {height: 30px;line-height: 30px;width: 30px;}
        .single-news-page-area .single-news-page h3 a {font-size: 17px;}
        .single-news-page-area .author-post .single-author-post .media .pull-left, .single-news-page-area .author-comment .single-author-comment .media .pull-left {margin-bottom: 30px;width: 100%;}
        .single-news-page-area .author-comment .single-author-comment .media .media-body ul li.right{display: inline-block;float: none !important;text-align: center;width: 100%;}
        .contact-us-page-area .contact-us-page h2{  text-align: center;font-size: 20px;}
        .contact-us-page-area .contact-box .single-contact-box {  border-right: 0px solid #e1e1e1;}
        .contact-us-page-area .leave-comments-area fieldset .btn-send { width: 100%;}
        .error-page-area .error-page h1{font-size: 100px;}
        .error-page-area .error-page{padding: 30px;}
        .nivo-directionNav a {top: 24%;}
        .home-callback-area .call-back-content h2 {font-size: 24px;}
        .our-project-one .pagination-area ul li a {height: 35px;line-height: 35px; width: 35px;}
        .home-counter-down-area .home-counter-down-title p {letter-spacing: 7px}
        .error-page-area .error-page p { font-size: 22px;}
        .home-news-area{padding: 50px 0 70px;}
        footer .footer-top-area {  padding: 150px 0 50px;}
        .home-about-area, .service-area, .home-faq-area, .home-counter-down-area, .home-team-area, .home-callback-area, .inner-page-header, .inner-page .about-page-content, .inner-page .footer-top-area, .total-blog-area, .call-top-action, .our-project-one, .our-project-two, .news-page-area, .contact-us-page-area, .error-page-area {  padding: 50px 0;}
    
    
    }
/*
    DEMO STYLE
*/


a,
a:hover,
a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
}

.clickable-link {
    line-height: 1.7 !important;
    font-size: 16px !important;
    display: block;
    margin-top: 16px ;
    color: #2a2929 !important;
}

.clickable-link:hover {
    text-decoration: underline;
    cursor: pointer;
}

.bottom_section {
    color: #ffffff;
    background-color: #17222d;
    padding: 30px 0 35px;
    font-size: 14px;
    margin-bottom: 0px;
}

.list-inline {
    padding-left: 0;
    list-style: none;
}

.bottom_section .social li {
    padding-right: 0px;
}

.bottom_section .section-head {
    margin-bottom: 20px;
    margin-top: 50px;
}
.bottom_section hr {
    border-top-color: #34495e;
    margin-bottom: 20px;
    margin-top: 20px;
}

.section-head header.centered {
    text-align: center;
}

.section-head hr:after {
    content: "";
    display: block;
    height: 1px;
    width: 80px;
    background-color: #1abc9c;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 0;
}

.row:before, .row:after {
    content: " ";
    display: table;
}

ul {
    margin-top: 0;
    margin-bottom: 1rem;
}

.list-inline > li {
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;
}

.bottom_section .social a {
    font-size: 17px;
    color: #34495e;
}

a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}

.navbar {
    padding: 15px 10px;
    background: #fff;
    border: none;
    border-radius: 0;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.navbar-btn {
    box-shadow: none;
    outline: none !important;
    border: none;
}

.line {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #ddd;
    margin: 40px 0;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

#sidebar {
    width: 250px;
    position: fixed;
    top: 0;
    left: -250px;
    height: 100vh;
    z-index: 999;
    background: #14233e;
    color: #fff;
    transition: all 0.3s;
    overflow-y: scroll;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
}

#sidebar.active {
    left: 0;
}

#dismiss {
    border: solid white;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    width: 15px;
    height: 15px;
    color: #fff;
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}

#dismiss:hover {
    /* background: #fff;
    color: #7386D5; */
}

.overlay {
    display: none;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    z-index: 998;
    opacity: 0;
    transition: all 0.5s ease-in-out;
}
.overlay.active {
    display: block;
    opacity: 1;
    top: 0px;
}

#sidebar .sidebar-header {
    padding: 20px;
    /* background: #6d7fcc; */
}

#sidebar ul.components {
    padding: 20px 0;
    border-bottom: 1px solid #47748b;
}

#sidebar ul p {
    color: #fff;
    padding: 10px;
}

#sidebar ul li a {
    padding: 10px;
    /* font-size: 1.1em; */
    display: block;
}

#sidebar ul li a:hover {
    color: #7386D5;
    background: #fff;
}

.dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
}

ul ul a {
    font-size: 0.9em !important;
    /* padding-left: 30px !important; */
    /* background: #6d7fcc; */
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */

#content {
    width: 100%;
    padding: 20px;
    min-height: 100vh;
    transition: all 0.3s;
    position: absolute;
    top: 0;
    right: 0;
}

.mobile-header {
    position: fixed;
    z-index: 9999;
    width: 100%;
}

@media screen and (min-width: 767px){
    .mobile-header-area {
        display: none;
    }
}
.wrapper{
    margin-top: 50px;
    justify-content: center;
}

h1 {
    color: #14233e;
    font-weight: 700;
    margin: 20px 0px;
}


.legend  {
    color: white !important;
    background-color: #14233e99 !important;
    display: flex;
    justify-content: center;
    align-content: center !important;
    align-items: center !important;
    align-self: center !important;
    /* width: 50% !important; */
    bottom: 10% !important;
    font-size: 30px !important;
    font-weight: bold !important;
    /* left: 70% !important; */
}

.home-container {
    align-items: center;
    align-content: center;
    align-self: center
}

.thumbs {
    display: flex;
    align-items: center;
    align-content: center;
    align-self: center
}

.carousal-slider {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.carousel {
    height: 400px;
    width: 100%;
    height: 100%;
    background-color: #14233e99;
    display: flex;
    justify-content: center
  }
.flip-box {
    background-color: transparent;
    width: 300px;
    height: 200px;
    perspective: 1000px;
    margin-bottom: 20px;

  }
  
  .flip-box-inner {
    position: relative;
    width: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
        height: 100%;
  }
  
  .flip-box:hover .flip-box-inner {
    transform: rotateY(180deg);
    /* border-bottom: 5px solid #14233e; */
  }
  
  .flip-box-front, .flip-box-back {
    position: absolute;
    width: 100%;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
  }
  
  .flip-box-front {
    background-color: #fff;
    color: black;
    border-bottom: 5px solid #14233e;
    height: 100%;
  }
  
  .flip-box-back {
    background-color: rgb(239, 239, 239);
    color: #000;
    transform: rotateY(180deg);
  }
  .flipBoxWrapper {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 0px;
    padding-top: 15px;
  }
.theme-image {
    height: 450px;
    width: 100%;
}

.theme-container {
    display: flex;
    flex-direction: column;
    background-color: white;
}

.theme-wrapper {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-self: center;
}

.bottom_section .about-us p {
    color: #4a5764;
}
.bottom_section .logo-area {
    width: 150px;
}
.bottom_section .logo-area  img{
    width: 100%;
}

.about-us .section-wrapper {
    display: flex; justify-content: center;
}


 ul.custom-points {
    list-style-type: square !important;
  }

  .analList {
    /* background: #f2f2f2 none repeat scroll 0 0; */
    border: 1px solid #ddd;
    padding: 20px;
    height: 100%;
    box-shadow: 0 0 5px 5px #c3c9ce;
}

.list-had {
    font-weight: bold;
}
.servList [class*="col-"], .services [class*="col-"] {
    margin-bottom: 30px;
  }
.dt-service {
    height: 100%;
}
.forma {
    margin-bottom: -80px;
    z-index: 10;
    margin-top: 48px;
}

.cont {
    margin-top: 44px;
    margin-left: -33px;
    font-family: 'Roboto', 'sans-serif';
    font-weight: 300;
    font-size: 13px;
    color: #666666;
    margin-bottom: -80px;
    z-index: 10;
}

.forma input {
    border-radius: 4px;
    padding-top: 10px;
    padding-bottom: 10px;
    border: 0px;
    font-family: 'Roboto', 'sans-serif';
    font-weight: 300;
    font-size: 14px;
    color: #555555;
    background: #ededed;
}
/* Made with love by Mutiullah Samim*/

.main-body{
background-image: url('http://getwallpapers.com/wallpaper/full/a/5/d/544750.jpg');
background-size: cover;
background-repeat: no-repeat;
height: 100vh;
font-family: 'Numans', sans-serif;
}

.container{
height: 100%;
align-content: center;
}

.card{
height: 370px;
margin-top: auto;
margin-bottom: auto;
width: 400px;
background-color: rgba(0,0,0,0.5) !important;
}

.social_icon span{
font-size: 60px;
margin-left: 10px;
color: #FFC312;
}

.social_icon span:hover{
color: white;
cursor: pointer;
}

.card-header h3{
color: white;
}

.social_icon{
position: absolute;
right: 20px;
top: -45px;
}

.input-group-prepend span{
width: 50px;
background-color: #FFC312;
color: black;
border:0 !important;
}

input:focus{
outline: 0 0 0 0  !important;
box-shadow: 0 0 0 0 !important;

}

.remember{
color: white;
}

.remember input
{
width: 20px;
height: 20px;
margin-left: 15px;
margin-right: 5px;
}

.login_btn{
color: black;
background-color: #FFC312;
width: 100px;
}

.login_btn:hover{
color: black;
background-color: white;
}

.links{
color: white;
}

.links a{
margin-left: 4px;
}
