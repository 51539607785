/*
    DEMO STYLE
*/

@import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700";


a,
a:hover,
a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
}

.clickable-link {
    line-height: 1.7 !important;
    font-size: 16px !important;
    display: block;
    margin-top: 16px ;
    color: #2a2929 !important;
}

.clickable-link:hover {
    text-decoration: underline;
    cursor: pointer;
}

.bottom_section {
    color: #ffffff;
    background-color: #17222d;
    padding: 30px 0 35px;
    font-size: 14px;
    margin-bottom: 0px;
}

.list-inline {
    padding-left: 0;
    list-style: none;
}

.bottom_section .social li {
    padding-right: 0px;
}

.bottom_section .section-head {
    margin-bottom: 20px;
    margin-top: 50px;
}
.bottom_section hr {
    border-top-color: #34495e;
    margin-bottom: 20px;
    margin-top: 20px;
}

.section-head header.centered {
    text-align: center;
}

.section-head hr:after {
    content: "";
    display: block;
    height: 1px;
    width: 80px;
    background-color: #1abc9c;
    position: absolute;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    bottom: 0;
}

.row:before, .row:after {
    content: " ";
    display: table;
}

ul {
    margin-top: 0;
    margin-bottom: 1rem;
}

.list-inline > li {
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;
}

.bottom_section .social a {
    font-size: 17px;
    color: #34495e;
}

a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}

.navbar {
    padding: 15px 10px;
    background: #fff;
    border: none;
    border-radius: 0;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.navbar-btn {
    box-shadow: none;
    outline: none !important;
    border: none;
}

.line {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #ddd;
    margin: 40px 0;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

#sidebar {
    width: 250px;
    position: fixed;
    top: 0;
    left: -250px;
    height: 100vh;
    z-index: 999;
    background: #14233e;
    color: #fff;
    transition: all 0.3s;
    overflow-y: scroll;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
}

#sidebar.active {
    left: 0;
}

#dismiss {
    border: solid white;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    width: 15px;
    height: 15px;
    color: #fff;
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}

#dismiss:hover {
    /* background: #fff;
    color: #7386D5; */
}

.overlay {
    display: none;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    z-index: 998;
    opacity: 0;
    transition: all 0.5s ease-in-out;
}
.overlay.active {
    display: block;
    opacity: 1;
    top: 0px;
}

#sidebar .sidebar-header {
    padding: 20px;
    /* background: #6d7fcc; */
}

#sidebar ul.components {
    padding: 20px 0;
    border-bottom: 1px solid #47748b;
}

#sidebar ul p {
    color: #fff;
    padding: 10px;
}

#sidebar ul li a {
    padding: 10px;
    /* font-size: 1.1em; */
    display: block;
}

#sidebar ul li a:hover {
    color: #7386D5;
    background: #fff;
}

.dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
}

ul ul a {
    font-size: 0.9em !important;
    /* padding-left: 30px !important; */
    /* background: #6d7fcc; */
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */

#content {
    width: 100%;
    padding: 20px;
    min-height: 100vh;
    transition: all 0.3s;
    position: absolute;
    top: 0;
    right: 0;
}

.mobile-header {
    position: fixed;
    z-index: 9999;
    width: 100%;
}

@media screen and (min-width: 767px){
    .mobile-header-area {
        display: none;
    }
}
.wrapper{
    margin-top: 50px;
    justify-content: center;
}

h1 {
    color: #14233e;
    font-weight: 700;
    margin: 20px 0px;
}

