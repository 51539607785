.flip-box {
    background-color: transparent;
    width: 300px;
    height: 200px;
    perspective: 1000px;
    margin-bottom: 20px;

  }
  
  .flip-box-inner {
    position: relative;
    width: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
        height: 100%;
  }
  
  .flip-box:hover .flip-box-inner {
    transform: rotateY(180deg);
    /* border-bottom: 5px solid #14233e; */
  }
  
  .flip-box-front, .flip-box-back {
    position: absolute;
    width: 100%;
    backface-visibility: hidden;
  }
  
  .flip-box-front {
    background-color: #fff;
    color: black;
    border-bottom: 5px solid #14233e;
    height: 100%;
  }
  
  .flip-box-back {
    background-color: rgb(239, 239, 239);
    color: #000;
    transform: rotateY(180deg);
  }
  .flipBoxWrapper {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 0px;
    padding-top: 15px;
  }