.forma {
    margin-bottom: -80px;
    z-index: 10;
    margin-top: 48px;
}

.cont {
    margin-top: 44px;
    margin-left: -33px;
    font-family: 'Roboto', 'sans-serif';
    font-weight: 300;
    font-size: 13px;
    color: #666666;
    margin-bottom: -80px;
    z-index: 10;
}

.forma input {
    border-radius: 4px;
    padding-top: 10px;
    padding-bottom: 10px;
    border: 0px;
    font-family: 'Roboto', 'sans-serif';
    font-weight: 300;
    font-size: 14px;
    color: #555555;
    background: #ededed;
}