 ul.custom-points {
    list-style-type: square !important;
  }

  .analList {
    /* background: #f2f2f2 none repeat scroll 0 0; */
    border: 1px solid #ddd;
    padding: 20px;
    height: 100%;
    box-shadow: 0 0 5px 5px #c3c9ce;
}

.list-had {
    font-weight: bold;
}
.servList [class*="col-"], .services [class*="col-"] {
    margin-bottom: 30px;
  }
.dt-service {
    height: 100%;
}