.theme-image {
    height: 450px;
    width: 100%;
}

.theme-container {
    display: flex;
    flex-direction: column;
    background-color: white;
}

.theme-wrapper {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-self: center;
}

.bottom_section .about-us p {
    color: #4a5764;
}
.bottom_section .logo-area {
    width: 150px;
}
.bottom_section .logo-area  img{
    width: 100%;
}

.about-us .section-wrapper {
    display: flex; justify-content: center;
}

